@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap'); /* Import Open Sans */

.home-wrapper {
  font-family: 'Open Sans', sans-serif; /* Use Open Sans for the entire Home component */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  width: 95%;
  height: 800px;
  background-size: cover;
  background-position: center;
  margin-bottom: 40px;
  border-radius: 10px;
  margin-left: auto; /* Center the banner */
  margin-right: auto; 
}

.services-section {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 20px;
}

.service {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  flex: 1;
  min-width: 200px;
  max-width: 500px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: normal; /* Ensure normal font weight */
  background-color: lightgray;
  border-radius: 40px;
}

.services {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.service h4 {
  font-size: 24px; /* Increased font size for service titles */
  margin-bottom: 30px; /* Space between title and image */
  font-weight: bold;
}

.service p {
  font-family: 'Open Sans', sans-serif; /* Use Open Sans for descriptions */
  font-size: 18px; /* Decrease font size slightly for better readability */
  line-height: 1.6; /* Better line spacing for readability */
  color: #333; /* Darker color for text */
  text-align: justify; /* Justified text for better alignment */
  margin: 20px; /* Space between image and description */
}

.services-section h2 {
  font-weight: bolder;
}

.contact-section {
  width: 100%; /* Ensure the section spans the full width */
  padding: 20px;
  background-color: #e0f7fa; /* Light blue background */
  border-radius: 10px;
  margin-top: 20px;
  text-align: center;
  font-size: 20px;
}

.contact-section h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.phone-section p {
  font-family: 'Open Sans', sans-serif;
  text-align: center;

}

.whatsapp-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #25D366;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;
  margin-left: auto; /* Center the banner */
  margin-right: auto;

}

.whatsapp-button img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.whatsapp-floating-button {
  position: fixed; /* Fixed position to stay at the bottom right */
  bottom: 20px; /* Distance from the bottom */
  right: 20px; /* Distance from the right */
  background-color: #25D366;
  color: white;
  padding: 10px 15px;
  border-radius: 50%;
  text-decoration: none;
  font-size: 24px;
  z-index: 1000; /* Ensure it stays on top */
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsapp-floating-button img {
  width: 30px;
  height: 33px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .container {
    background-color: #a8cdd6; /* Background color for the container */
    padding: 20px;
    box-sizing: border-box;
    margin: 0 auto; /* Center align the container */
    max-width: 1700px; /* Maximum width of the container */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .banner {
    height: 200px; /* Reduce height for mobile */
    width: 100%;
    margin-bottom: 20px;
  }


  .services {
    flex-direction: column; /* Stack services vertically on mobile */
    align-items: center; /* Center align services */
  }

  .service {
    width: 100%; /* Ensure full width on mobile */
    max-width: none; /* Remove max-width constraint */
    margin: 10px 0; /* Reduce margin for mobile */
  }

  .service h4 {
    font-size: 20px; /* Reduce font size for titles on mobile */
  }

  .service p {
    font-size: 16px; /* Reduce font size for descriptions on mobile */
  }

  .contact-section h3 {
    font-size: 20px; /* Reduce font size for contact section title */
  }

  .whatsapp-floating-button {
    padding: 8px 12px; /* Adjust padding for smaller screens */
    font-size: 20px; /* Reduce font size for smaller screens */
  }

  .whatsapp-floating-button img {
    width: 24px;
    height: 24px;
  }

  .contact-section {
    width: 90%;
    margin-left: auto;
    margin-right: auto;

  }
}

