.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f9fa;
  padding: 10px 20px;
  font-family: 'Open Sans', sans-serif;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.header-left {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px;
  margin-right: 20px;
}

.title {
  font-size: 26px;
  margin: 0;
}

.nav {
  display: flex;
  align-items: center;
  gap: 15px;
}

.nav-link,
.external-link {
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.nav-link:hover,
.external-link:hover {
  text-decoration: underline;
}

.language-switcher {
  padding: 5px;
  font-size: 16px;
}

.hamburger {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .header-wrapper {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    margin-bottom: 20px;
    transition: height 0.3s ease; /* Smooth transition for expanding */
  }

  .header-left {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    height: 40px;
    margin-right: 10px;
  }

  .title {
    font-size: 20px;
    margin: 0; /* Remove margin to align properly */
  }

  .nav {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    display: none; /* Hide the navigation by default on mobile */
    background-color: #f8f9fa; /* Background color for the dropdown */
    padding: 10px 0; /* Padding for the dropdown */
  }

  .nav.open {
    display: flex; /* Show the navigation when menu is open */
  }

  .nav-link,
  .external-link {
    font-size: 16px;
    width: 100%; /* Full width links */
    padding: 10px 20px; /* Padding for links */
    box-sizing: border-box;
  }

  .language-switcher {
    font-size: 14px;
    width: 20%; /* Full width for select with padding consideration */
    margin: 10px 10px; /* Margin for alignment */
  }

  .hamburger {
    display: block; /* Show hamburger icon on mobile */
  }
}

.language-switcher {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 16px;
  color: #000;
  gap: 5px;
}

.flag-icon {
  width: 20px;
  height: 20px;
}