@import url('https://fonts.googleapis.com/css2?family=Futura:wght@400;700&display=swap');
@import './styles/header.css';
@import './styles/home.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Futura';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header, nav, h1, h2, h3, h4, h5, h6, p, a {
  font-family: 'Open Sans', sans-serif;
}

.container {
  background-color: #ffffff; /* Background color for the container */
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto; /* Center align the container */
  max-width: 1700px; /* Maximum width of the container */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
